const AddressComponent = ({ guest }) => {
  return (
    <>
      {
        <>
          <h4 style={{ marginTop: 50 }}>
            Tại địa chỉ {`nhà ${guest?.guestOf === "vk" ? "gái" : "trai"}`}
          </h4>
          <h2>
            {guest?.guestOf === "vk" ? "Thôn nội 1" : "Ngã tư Táo Ngoại"}, Xã
            Tam Thuấn, Huyện Phúc Thọ, TP.Hà Nội
          </h2>
          <p className="welcome-text">
            Sự hiện diện của bạn sẽ là niềm vinh hạnh lớn của chúng tôi!
          </p>
        </>
      }
    </>
  );
};

export default AddressComponent;
