import { useCallback, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getToken } from "../utils/storage";

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const [token, setToken] = useState();

  useEffect(() => {
    const localToken = getToken()
    setToken(localToken);
  }, []);

  const handleAuth = useCallback(() => {
    if (!token && location.pathname !== "/admin/login") {
      return <Navigate to="/admin/login" replace />;
    }
    if (token && location.pathname === "/admin/login") {
      return <Navigate to="/admin" replace />;
    }
    return children;
  }, [token, location, children]);

  return handleAuth();
};

export default RequireAuth;
