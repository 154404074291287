import {
  anh1,
  anh10,
  anh11,
  anh12,
  anh13,
  anh14,
  anh15,
  anh16,
  anh17,
  anh18,
  anh19,
  anh2,
  anh20,
  anh21,
  anh22,
  anh23,
  anh24,
  anh25,
  anh26,
  anh27,
  anh28,
  anh29,
  anh3,
  anh30,
  anh31,
  anh32,
  anh33,
  anh34,
  anh35,
  anh36,
  anh37,
  anh39,
  anh4,
  anh40,
  anh41,
  anh42,
  anh43,
  anh44,
  anh45,
  anh46,
  anh47,
  anh48,
  anh49,
  anh5,
  anh50,
  anh51,
  anh52,
  anh53,
  anh54,
  anh55,
  anh56,
  anh57,
  anh58,
  anh59,
  anh6,
  anh60,
  anh7,
  anh8,
  anh9,
} from "../images";

export const photos = [
  {
    src: anh1,
    width: 4,
    height: 6,
  },
  {
    src: anh2,
    width: 4,
    height: 6,
  },
  {
    src: anh3,
    width: 6,
    height: 4,
  },
  {
    src: anh4,
    width: 4,
    height: 6,
  },
  {
    src: anh5,
    width: 6,
    height: 4,
  },
  {
    src: anh6,
    width: 4,
    height: 6,
  },
  {
    src: anh7,
    width: 6,
    height: 4,
  },
  {
    src: anh8,
    width: 6,
    height: 4,
  },
  {
    src: anh10,
    width: 6,
    height: 4,
  },
  {
    src: anh9,
    width: 4,
    height: 6,
  },
  {
    src: anh11,
    width: 4,
    height: 6,
  },
  {
    src: anh12,
    width: 4,
    height: 6,
  },
  {
    src: anh13,
    width: 4,
    height: 6,
  },
  {
    src: anh14,
    width: 4,
    height: 6,
  },
  {
    src: anh15,
    width: 4,
    height: 6,
  },
  {
    src: anh16,
    width: 4,
    height: 6,
  },
  {
    src: anh17,
    width: 4,
    height: 6,
  },
  {
    src: anh18,
    width: 4,
    height: 6,
  },
  {
    src: anh19,
    width: 4,
    height: 6,
  },
  {
    src: anh20,
    width: 4,
    height: 6,
  },
  {
    src: anh21,
    width: 4,
    height: 6,
  },
  {
    src: anh22,
    width: 4,
    height: 6,
  },
  {
    src: anh23,
    width: 4,
    height: 6,
  },
  {
    src: anh24,
    width: 4,
    height: 6,
  },
  {
    src: anh25,
    width: 4,
    height: 6,
  },
  {
    src: anh26,
    width: 4,
    height: 6,
  },
  {
    src: anh27,
    width: 4,
    height: 6,
  },
  {
    src: anh28,
    width: 4,
    height: 6,
  },
  {
    src: anh29,
    width: 4,
    height: 6,
  },
  {
    src: anh30,
    width: 6,
    height: 4,
  },
  {
    src: anh31,
    width: 4,
    height: 6,
  },
  {
    src: anh32,
    width: 4,
    height: 6,
  },
  {
    src: anh33,
    width: 4,
    height: 6,
  },
  {
    src: anh34,
    width: 4,
    height: 6,
  },
  {
    src: anh35,
    width: 4,
    height: 6,
  },
  {
    src: anh36,
    width: 4,
    height: 6,
  },
  {
    src: anh37,
    width: 4,
    height: 6,
  },
  {
    src: anh39,
    width: 6,
    height: 4,
  },
  {
    src: anh40,
    width: 4,
    height: 6,
  },
  {
    src: anh41,
    width: 4,
    height: 6,
  },
  {
    src: anh42,
    width: 4,
    height: 6,
  },
  {
    src: anh43,
    width: 4,
    height: 6,
  },
  {
    src: anh44,
    width: 4,
    height: 6,
  },
  {
    src: anh45,
    width: 4,
    height: 6,
  },
  {
    src: anh46,
    width: 6,
    height: 4,
  },
  {
    src: anh47,
    width: 6,
    height: 4,
  },
  {
    src: anh48,
    width: 4,
    height: 6,
  },
  {
    src: anh49,
    width: 4,
    height: 6,
  },
  {
    src: anh50,
    width: 4,
    height: 6,
  },
  {
    src: anh51,
    width: 4,
    height: 6,
  },
  {
    src: anh52,
    width: 4,
    height: 6,
  },
  {
    src: anh53,
    width: 6,
    height: 4,
  },
  {
    src: anh54,
    width: 6,
    height: 4,
  },
  {
    src: anh55,
    width: 4,
    height: 6,
  },
  {
    src: anh56,
    width: 6,
    height: 4,
  },
  {
    src: anh57,
    width: 4,
    height: 6,
  },
  {
    src: anh58,
    width: 6,
    height: 4,
  },
  {
    src: anh59,
    width: 4,
    height: 6,
  },
  {
    src: anh60,
    width: 4,
    height: 6,
  },
];
