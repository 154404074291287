import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Col, FloatButton, Input, List, Radio, Row } from "antd";
import html2canvas from "html2canvas";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import { useSearchParams } from "react-router-dom";
import apiRepository from "../api/httpRepository";
import {
  imgAnd,
  imgBot1,
  imgBot2,
  imgChuRe,
  imgCoDau,
  imgDecor1,
  imgDecor2,
  imgDecor3,
  imgDecor4,
  imgDecor5,
  imgDecor6,
  imgDecorLeft,
  imgLeftFlower,
  imgMidLineBottom,
  imgMidLineTop,
  imgRightFlower,
  imgRing,
  imgSingleFlower,
} from "../images";
import { photos } from "../utils/photos";
import { string2Date } from "../utils/time";
import AddressComponent from "./AddressComponent";
import DonateComponent from "./DonateComponent";
import DonateModal from "../modal/DonateModal";

const { TextArea } = Input;

const Home = () => {
  const [searchParams] = useSearchParams();
  const [guest, setGuest] = useState();
  const [wishData, setWishData] = useState();
  const [wish, setWish] = useState();
  const [isAttend, setAttend] = useState();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [picture, setPicture] = useState([]);
  const [pictureIndex, setPictureIndex] = useState(0);

  const getPhotos = useMemo(() => _.shuffle(photos), [photos]);

  const seeMore = useCallback(() => {
    const index = pictureIndex + 9;
    const pictureMore = _.slice(getPhotos, pictureIndex, index);
    setPicture(_.concat(picture, pictureMore));
    setPictureIndex(index);
  }, [getPhotos, pictureIndex, setPictureIndex]);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const handleGetWishData = useCallback(() => {
    apiRepository.apiGetListWish().then((response) => {
      if (response.status === 200) {
        setWishData(_.filter(response.data, (i) => i.id !== guest?.id));
      }
    });
  }, [guest]);

  const handleGetGuest = useCallback(() => {
    const id = searchParams.get("id");
    if (id) {
      apiRepository.apiGetGuestInfo(id).then((response) => {
        if (response.status === 200) {
          setGuest(response.data);
        }
      });
    }
  }, [searchParams, setGuest]);

  const guestDate = useMemo(() => string2Date(guest?.date), [guest]);

  useEffect(() => {
    seeMore();
    handleGetGuest();
  }, []);

  useEffect(() => {
    setAttend(guest?.isAttend);
    handleGetWishData();
  }, [guest]);

  const onSubmit = useCallback(async () => {
    const data = { id: guest?.id };
    if (!_.isNil(isAttend)) {
      data.isAttend = isAttend;
    }
    if (!_.isEmpty(wish)) {
      data.wish = wish;
    }
    await apiRepository.apiUpdateGuestInfo(data);
    setWish("");
    handleGetGuest();
  }, [wish, isAttend, guest, handleGetGuest]);

  const getAttend = useMemo(() => {
    if (_.isNil(isAttend)) return "";
    return isAttend ? "yes" : "no";
  }, [isAttend]);

  return (
    <div className="home-page">
      <div id="wedding-card">
        {/* Header */}
        <Row className="header-row">
          <Col xs={2} sm={2} md={6} lg={6}>
            <img
              style={{ float: "left" }}
              className="img-header"
              src={imgLeftFlower}
            />
          </Col>
          <Col className="content" xs={20} sm={20} md={12} lg={12}>
            <h1 className="text">Chúng tôi cưới</h1>
            <img className="img-line" src={imgMidLineTop} />
            <Row style={{ alignItems: "center" }}>
              <Col className="name" xs={24} sm={24} md={24} lg={10}>
                <span>Quang Hưng</span>
              </Col>
              <Col xs={24} sm={24} md={24} lg={4}>
                <img className="and-img" src={imgAnd} />
              </Col>
              <Col className="name" xs={24} sm={24} md={24} lg={10}>
                <span>Ngọc Anh</span>
              </Col>
            </Row>
            <img className="img-line" src={imgMidLineBottom} />
            <div className="dot-txt" />
            <p className="day-txt text">07.01.2024</p>
            <img className="flower-bottom-img" src={imgSingleFlower} />
          </Col>
          <Col xs={2} sm={2} md={6} lg={6}>
            <img
              style={{ float: "right" }}
              className="img-header"
              src={imgRightFlower}
            />
          </Col>
        </Row>

        {/* Thông tin khách mời */}
        <Row className="guest-info">
          <Col xs={2} sm={2} md={4} lg={4}>
            <img
              style={{ float: "left" }}
              className="img-header"
              src={imgDecor1}
            />
          </Col>
          <Col className="content" xs={20} sm={20} md={16} lg={16}>
            <h2>Trân trọng kính mời</h2>
            {guest && <h3 className="guest-name">{guest.name}</h3>}
            {guest ? (
              <h4>Tới dự bữa cơm thân mật của hai vợ chồng</h4>
            ) : (
              <h4>Tới dự hôn lễ của hai vợ chồng</h4>
            )}
            <h4>Vào hồi</h4>
            <Row className="time-row">
              <Col className="content" xs={24} sm={24} md={8} lg={8}>
                <h4>Thời gian</h4>
                <h1>{guest ? guest.time : "13:05"}</h1>
              </Col>
              <Col className="mid-time content" xs={24} sm={24} md={8} lg={8}>
                <h4>{guestDate.dayOfWeek}</h4>
                <h1>{guestDate.day}</h1>
              </Col>
              <Col className="content" xs={24} sm={24} md={8} lg={8}>
                <h4>Tháng</h4>
                <h1>{`${guestDate.month}/${guestDate.year}`}</h1>
              </Col>
            </Row>
            <AddressComponent guest={guest} />
          </Col>
          <Col xs={2} sm={2} md={4} lg={4}>
            <img
              style={{ float: "right" }}
              className="img-header"
              src={imgDecor2}
            />
          </Col>
        </Row>

        {/* Hai họ */}
        <Row className="hai-ho-row">
          <Col className="content" xs={24} sm={24} md={10} lg={10}>
            <p className="second-text">Nhà trai:</p>
            <h3>Nguyễn Văn Thái</h3>
            <h3>Nguyễn Thị Thường</h3>
            <p className="second-text">
              Ngã tư Táo Ngoại, Tam Thuấn, Phúc Thọ, HN
            </p>
          </Col>
          <Col xs={2} sm={2} md={4} lg={4}>
            <img src={imgRing} />
          </Col>
          <Col className="content" xs={24} sm={24} md={10} lg={10}>
            <p className="second-text">Nhà gái:</p>
            <h3>Dương Ngọc Duyên</h3>
            <h3>Nguyễn Thị Thư</h3>
            <p className="second-text">Thôn Nội 1, Tam Thuấn, Phúc Thọ, HN</p>
          </Col>
        </Row>

        {/* Chú Rể */}
        <Row className="chu-re-row">
          <Col xs={24} sm={24} md={8} lg={8}>
            <div className="main-img">
              <img className="human-img" src={imgChuRe} />
              <img className="bottom-flower-img" src={imgSingleFlower} />
            </div>
          </Col>
          <Col className="content" xs={24} sm={24} md={12} lg={12}>
            <h3>Chú rể</h3>
            <h2>Nguyễn Quang Hưng</h2>
            <p>
              Là "người hùng" của trái tim cô dâu và những người thân yêu. Được
              biết đến với sự hồn nhiên như một đứa trẻ, chú rể của chúng ta
              không chỉ có một tình yêu mãnh liệt, mà còn là một người bạn đồng
              hành, sẵn sàng cười đùa và tận hưởng mọi khoảnh khắc bên người ấy.
              ♥
            </p>
          </Col>
          <Col className="flower" xs={24} sm={24} md={4} lg={4}>
            <img
              style={{ float: "right" }}
              className="img-header"
              src={imgDecor4}
            />
          </Col>
        </Row>

        {/* Cô dâu */}
        <Row className="co-dau-row">
          <Col className="flower" xs={24} sm={24} md={4} lg={4}>
            <img
              style={{ float: "left" }}
              className="img-header"
              src={imgDecor3}
            />
          </Col>
          <Col className="col-one" xs={24} sm={24} md={8} lg={8}>
            <div className="main-img">
              <img className="human-img" src={imgCoDau} />
              <img className="bottom-flower-img" src={imgDecor5} />
            </div>
          </Col>
          <Col className="content" xs={24} sm={24} md={12} lg={12}>
            <h3>Cô dâu</h3>
            <h2>Dương Thị Ngọc Anh</h2>
            <p>
              Chào mừng đến với ngày trọng đại, nơi những đóa hoa hồng và sự
              tinh khôi của tình yêu hoàn hảo hóa thành hình dáng nàng dâu của
              chúng ta, đẹp như một bức tranh tuyệt vời. Chúng ta hãy cùng bước
              chân vào thế giới mộng của cô dâu xinh đẹp - Ngọc Anh ♥
            </p>
          </Col>
          <Col className="col-two" xs={24} sm={24} md={8} lg={8}>
            <div className="main-img">
              <img className="human-img" src={imgCoDau} />
              <img className="bottom-flower-img" src={imgDecor5} />
            </div>
          </Col>
        </Row>
      </div>

      {/* Albums */}
      <Row justify="center" className="album-row">
        <Col className="header-image-row" span={24}>
          <img src={imgDecor6} />
        </Col>
        <Col xs={24} sm={24} md={21} lg={21}>
          <Gallery columns={250} photos={picture} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={getPhotos.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </Col>
        {picture.length < getPhotos.length && (
          <Col className="see-more-picture" span={24}>
            <a onClick={seeMore}>Xem thêm</a>
          </Col>
        )}
        <Col className="header-image-bottom-row" span={24}>
          <img src={imgDecor6} />
        </Col>
      </Row>

      {/* Lưu bút */}
      <h2 className="luu-but-title">Lời chúc phúc</h2>
      <Row justify="center" className="luu-but-row">
        <Col className="flower-left" xs={2} sm={2} md={4} lg={4}>
          <img src={imgDecorLeft} />
        </Col>
        <Col className="list-data-content" xs={20} sm={20} md={16} lg={16}>
          <List
            itemLayout="horizontal"
            dataSource={wishData}
            header={
              guest &&
              guest.isShowWish && (
                <Row className="list-wish-header">
                  <Col className="guest-name" xs={24} sm={24} md={7} lg={7}>
                    <h4>{guest.name}</h4>
                    <p className="time">
                      {moment(guest.updatedAt).format("hh:mm - DD/MM/YYYY")}
                    </p>
                  </Col>
                  <Col className="content" xs={24} sm={24} md={17} lg={17}>
                    <p>{guest.wish}</p>
                  </Col>
                </Row>
              )
            }
            renderItem={(item) => (
              <Row className="list-wish">
                <Col className="guest-name" xs={24} sm={24} md={7} lg={7}>
                  <h4>{item.name}</h4>
                  <p className="time">
                    {moment(item.updatedAt).format("hh:mm - DD/MM/YYYY")}
                  </p>
                </Col>
                <Col className="content" xs={24} sm={24} md={17} lg={17}>
                  <p>{item.wish}</p>
                </Col>
              </Row>
            )}
          />
        </Col>
        <Col className="flower-right" xs={2} sm={2} md={4} lg={4}>
          <img src={imgDecor1} />
        </Col>
        <Col className="header-image-bottom-row" span={24}>
          <img src={imgDecor6} />
        </Col>
      </Row>

      {/* Đăng ký tham dự */}
      {guest && (
        <Row justify="center" className="tham-du-row">
          <Col className="flower-left" xs={0} sm={0} md={4} lg={4}>
            <img src={imgBot1} />
          </Col>
          <Col className="tham-du-left" xs={24} sm={24} md={8} lg={8}>
            <div>
              <h2>Bạn sẽ đến chứ?</h2>
              <p style={{ color: "#8b8b8b" }}>
                Chúng tôi rất chờ đón sự kiện trọng đại này của cuộc đời. Đám
                cưới của chúng tôi sẽ trọn vẹn hơn khi có thêm lời chúc phúc và
                sự hiện diện của các bạn. Xin hãy xác nhận sự có mặt của mình để
                chúng tôi chuẩn bị đón tiếp một cách chu đáo nhất nhé! Trân
                trọng!
              </p>
            </div>
          </Col>
          <Col className="tham-du-right" xs={24} sm={24} md={8} lg={8}>
            {guest && _.isEmpty(guest.wish) && (
              <div className="wish-input">
                <p>Gửi lời chúc:</p>
                <TextArea
                  rows={4}
                  className="wish-input"
                  onChange={({ target }) => setWish(target.value)}
                  placeholder="Nhập lời chúc của bạn tại đây..."
                  maxLength={200}
                />
              </div>
            )}
            <div className="attend-input">
              <p>Bạn sẽ tới tham dự chứ:</p>
              <Radio.Group
                onChange={({ target }) =>
                  setAttend(target.value === "yes" ? true : false)
                }
                value={getAttend}
                buttonStyle="solid"
              >
                <Radio.Button value="yes">Có tham dự</Radio.Button>
                <Radio.Button value="no">Không tham dự</Radio.Button>
              </Radio.Group>
            </div>
            {((!_.isNil(isAttend) && guest?.isAttend !== isAttend) ||
              !_.isEmpty(wish)) && (
              <Button
                onClick={onSubmit}
                style={{ marginTop: 30, width: 150 }}
                type="primary"
                size="large"
              >
                Gửi
              </Button>
            )}
          </Col>
          <Col className="flower-right" xs={0} sm={0} md={4} lg={4}>
            <img src={imgBot2} />
          </Col>
        </Row>
      )}

      {/* Cảm ơn */}
      <Col className="footer" span={24}>
        <h2>Cảm ơn</h2>
      </Col>

      {/* Donate */}
      <DonateComponent />
    </div>
  );
};

export default Home;
