import { CloudDownloadOutlined } from "@ant-design/icons";
import { Col, FloatButton, Row } from "antd";
import html2canvas from "html2canvas";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import apiRepository from "../api/httpRepository";
import {
  imgAnd,
  imgDecor1,
  imgDecor2,
  imgLeftFlower,
  imgMidLineBottom,
  imgMidLineTop,
  imgRightFlower,
  imgRing,
  imgSingleFlower,
} from "../images";
import { string2Date } from "../utils/time";
import AddressComponent from "./AddressComponent";

const WeddingCard = () => {
  const [searchParams] = useSearchParams();
  const [guest, setGuest] = useState();

  const handleGetGuest = useCallback(() => {
    const id = searchParams.get("id");
    if (id) {
      apiRepository.apiGetGuestInfo(id).then((response) => {
        if (response.status === 200) {
          setGuest(response.data);
        }
      });
    }
  }, [searchParams, setGuest]);

  const guestDate = useMemo(() => string2Date(guest?.date), [guest]);

  useEffect(() => {
    handleGetGuest();
  }, []);

  const onDownloadWeddingCard = useCallback(async () => {
    const htmlRaw = document.getElementById("wedding-card");
    const canvas = await html2canvas(htmlRaw);
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = `${guest.name}.png`;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [guest]);

  return (
    <div className="home-page">
      {guest && (
        <FloatButton
          type="primary"
          icon={<CloudDownloadOutlined />}
          onClick={onDownloadWeddingCard}
          tooltip={<div>Tải về thiệp cưới</div>}
        />
      )}
      <div id="wedding-card">
        {/* Header */}
        <Row className="header-row">
          <Col xs={2} sm={2} md={6} lg={6}>
            <img
              style={{ float: "left" }}
              className="img-header"
              src={imgLeftFlower}
            />
          </Col>
          <Col className="content" xs={20} sm={20} md={12} lg={12}>
            <h1 className="text">Chúng tôi cưới</h1>
            <img className="img-line" src={imgMidLineTop} />
            <Row style={{ alignItems: "center" }}>
              <Col className="name" xs={24} sm={24} md={24} lg={10}>
                <span>Quang Hưng</span>
              </Col>
              <Col xs={24} sm={24} md={24} lg={4}>
                <img className="and-img" src={imgAnd} />
              </Col>
              <Col className="name" xs={24} sm={24} md={24} lg={10}>
                <span>Ngọc Anh</span>
              </Col>
            </Row>
            <img className="img-line" src={imgMidLineBottom} />
            <div className="dot-txt" />
            <p className="day-txt text">07.01.2024</p>
            <img className="flower-bottom-img" src={imgSingleFlower} />
          </Col>
          <Col xs={2} sm={2} md={6} lg={6}>
            <img
              style={{ float: "right" }}
              className="img-header"
              src={imgRightFlower}
            />
          </Col>
        </Row>

        {/* Thông tin khách mời */}
        <Row className="guest-info">
          <Col xs={2} sm={2} md={4} lg={4}>
            <img
              style={{ float: "left" }}
              className="img-header"
              src={imgDecor1}
            />
          </Col>
          <Col className="content" xs={20} sm={20} md={16} lg={16}>
            <h2>Trân trọng kính mời</h2>
            {guest && <h3 className="guest-name">{guest.name}</h3>}
            {guest ? (
              <h4>Tới dự bữa cơm thân mật của hai vợ chồng</h4>
            ) : (
              <h4>Tới dự hôn lễ của hai vợ chồng</h4>
            )}
            <h4>Vào hồi</h4>
            <Row className="time-row">
              <Col className="content" xs={24} sm={24} md={8} lg={8}>
                <h4>Thời gian</h4>
                <h1>{guest ? guest.time : "13:05"}</h1>
              </Col>
              <Col className="mid-time content" xs={24} sm={24} md={8} lg={8}>
                <h4>{guestDate.dayOfWeek}</h4>
                <h1>{guestDate.day}</h1>
              </Col>
              <Col className="content" xs={24} sm={24} md={8} lg={8}>
                <h4>Tháng</h4>
                <h1>{`${guestDate.month}/${guestDate.year}`}</h1>
              </Col>
            </Row>
            <AddressComponent guest={guest} />
          </Col>
          <Col xs={2} sm={2} md={4} lg={4}>
            <img
              style={{ float: "right" }}
              className="img-header"
              src={imgDecor2}
            />
          </Col>
        </Row>

        {/* Hai họ */}
        <Row className="hai-ho-row">
          <Col className="content" xs={24} sm={24} md={10} lg={10}>
            <p className="second-text">Nhà trai:</p>
            <h3>Nguyễn Văn Thái</h3>
            <h3>Nguyễn Thị Thường</h3>
            <p className="second-text">
              Ngã tư Táo Ngoại, Tam Thuấn, Phúc Thọ, HN
            </p>
          </Col>
          <Col xs={2} sm={2} md={4} lg={4}>
            <img src={imgRing} />
          </Col>
          <Col className="content" xs={24} sm={24} md={10} lg={10}>
            <p className="second-text">Nhà gái:</p>
            <h3>Dương Ngọc Duyên</h3>
            <h3>Nguyễn Thị Thư</h3>
            <p className="second-text">Thôn Nội 1, Tam Thuấn, Phúc Thọ, HN</p>
          </Col>
        </Row>

        {/* Cảm ơn */}
        <Col className="footer" span={24}>
          <h2>Cảm ơn</h2>
        </Col>
      </div>
    </div>
  );
};

export default WeddingCard;
