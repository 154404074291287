import { ConfigProvider } from "antd";
import { Navigate, Route, Routes } from "react-router-dom";
import Admin from "./components/Admin";
import Home from "./components/Home";
import Login from "./components/Login";
import RequireAuth from "./components/RequireAuth";
import WeddingCard from "./components/WeddingCard";

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#072048",
          borderRadius: 10,
          colorBgContainer: "white",
        },
      }}
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/wedding-card" element={<WeddingCard />} />
        <Route
          exact
          path="/admin/login"
          element={
            <RequireAuth>
              <Login />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/admin"
          element={
            <RequireAuth>
              <Admin />
            </RequireAuth>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </ConfigProvider>
  );
};

export default App;
