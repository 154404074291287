import { Modal, Tabs } from "antd";
import { ckNhaGai, ckNhaTrai } from "../images";

const DonateModal = ({ isOpen, onCancel }) => {
  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      forceRender={null}
      className="qr-code-modal"
    >
      <Tabs
        defaultActiveKey={1}
        type="card"
        size={"large"}
        items={[
          {
            label: "Nhà Trai",
            key: 1,
            children: (
              <div className="bank-info">
                <img src={ckNhaTrai} />
                <p style={{ marginTop: 20 }}>TPBank</p>
                <h4>02446426901</h4>
                <p>NGUYEN QUANG HUNG</p>
              </div>
            ),
          },
          {
            label: "Nhà Gái",
            key: 2,
            children: (
              <div className="bank-info">
                <img src={ckNhaGai} />
                <p style={{ marginTop: 20 }}>VIETINBANK</p>
                <h4>105002499306</h4>
                <p>DUONG THI NGOC ANH</p>
              </div>
            ),
          },
        ]}
      />
    </Modal>
  );
};

export default DonateModal;
